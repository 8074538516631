import api from "../../axios_service.js";

export async function fetchAtividadesEmAndamento(filtros) {
  const response = await api.get(
    `/gestor/dashboard/atividades-em-andamento${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchAtividadesAtrasadas(filtros) {
  const response = await api.get(
    `/gestor/dashboard/atividades-atrasadas${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchAtividadesAguardandoAprovacao(filtros) {
  const response = await api.get(
    `/gestor/dashboard/atividades-aguardando-aprovacao${filtros ? filtros : ""}`
  );
  return response.data.result;
}
