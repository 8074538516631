<template>
  <v-card class="mt-4">
    <v-card-title class=" text-h6 font-weight-bold pa-3"
      ><v-icon color="primary" left>mdi-briefcase-download</v-icon>No que
      estamos trabalhando Agora
    </v-card-title>

    <v-card-text class="pa-6">
      <v-data-table
        :headers="headers"
        :items="atividades"
        :items-per-page="8"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="editarAtividade"
      >
        <template v-slot:item.titulo="{ item }">
          {{ item.titulo | excerpt(15) }}
        </template>
        <template v-slot:item.descricao_servicos="{ item }">
          {{ item.descricao_servicos | excerpt(22) }}
        </template>
        <template v-slot:item.cliente_nome="{ item }">
          {{ item.cliente_nome | excerpt(15) }}
        </template>
        <template v-slot:item.inicio="{ item }">
          {{ item.inicio | dateFormat("dd/MM/yyyy") }}
        </template>
        <template v-slot:item.entrega="{ item }">
          {{ item.inicio | dateFormat("dd/MM/yyyy") }}
        </template>
      </v-data-table>
    </v-card-text>
    <AtividadesEditarTrabalho
      v-if="dialogEditAtividade"
      :dialogEditAtividade.sync="dialogEditAtividade"
      :atividadeSelected="atividadeSelected"
      :cliente_id="cliente_id"
    />
  </v-card>
</template>

<script>
import { fetchAtividadesEmAndamento } from "@/api/gestor/dashboard/atividades.js";
export default {
  name: "BoxAtividadesEmAndamento",

  data() {
    return {
      atividades: [],
      loading: false,
      dialogEditAtividade: false,
      atividadeSelected: null,
      cliente_id: null,
    };
  },

  components: {
    AtividadesEditarTrabalho: () =>
      import("@/views/gestor/atividades/AtividadesEditarTrabalho.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "Título",
          value: "titulo",
        },
        {
          text: "Descricao",
          value: "descricao_servicos",
        },
        {
          text: "Cliente",
          value: "cliente_nome",
        },
        {
          text: "Serviço",
          value: "servico_descricao",
        },
        {
          text: "Início",
          value: "inicio",
        },
        {
          text: "Entrega",
          value: "entrega",
        },
      ];
    },
  },

  methods: {
    getAtividadesEmAndamento() {
      this.loading = true;
      fetchAtividadesEmAndamento()
        .then((response) => {
          this.atividades = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    editarAtividade(item) {
      this.atividadeSelected = item.id;
      this.cliente_id = item.cliente_id;
      this.dialogEditAtividade = true;
    },
  },

  mounted() {
    this.getAtividadesEmAndamento();
  },
};
</script>

<style></style>
