var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-card-title',{staticClass:" text-h6 font-weight-bold pa-3"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-briefcase-download")]),_vm._v("No que estamos trabalhando Agora ")],1),_c('v-card-text',{staticClass:"pa-6"},[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.atividades,"items-per-page":8,"sort-desc":true},on:{"click:row":_vm.editarAtividade},scopedSlots:_vm._u([{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("excerpt")(item.titulo,15))+" ")]}},{key:"item.descricao_servicos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("excerpt")(item.descricao_servicos,22))+" ")]}},{key:"item.cliente_nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("excerpt")(item.cliente_nome,15))+" ")]}},{key:"item.inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inicio,"dd/MM/yyyy"))+" ")]}},{key:"item.entrega",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inicio,"dd/MM/yyyy"))+" ")]}}])})],1),(_vm.dialogEditAtividade)?_c('AtividadesEditarTrabalho',{attrs:{"dialogEditAtividade":_vm.dialogEditAtividade,"atividadeSelected":_vm.atividadeSelected,"cliente_id":_vm.cliente_id},on:{"update:dialogEditAtividade":function($event){_vm.dialogEditAtividade=$event},"update:dialog-edit-atividade":function($event){_vm.dialogEditAtividade=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }